import { navigate } from "gatsby-link"
import React, { useState } from "react"

const ContactForm = () => {
  const [isValidated, setIsValidated] = useState(false)

  function encode(data) {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }

  const handleChange = e => {
    setIsValidated({ ...isValidated, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...isValidated,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  return (
    <form
      name="contact"
      method="post"
      action="/kontakt/dziekujemy/"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={handleSubmit}
      className="mt-3"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value="contact" />
      <div hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </div>
      <div className="field mb-5">
        <label
          className="label is-uppercase is-size-7 has-text-weight-light has-letter-spacing has-text-right"
          htmlFor={"name"}
        >
          OD KOGO WIADOMOŚĆ
        </label>
        <div className="control">
          <input
            className="input is-size-6 has-text-weight-light has-letter-spacing is-styled-input"
            type={"text"}
            name={"name"}
            onChange={handleChange}
            id={"name"}
            required={true}
          />
        </div>
      </div>
      <div className="field mb-5">
        <label
          className="label is-uppercase is-size-7 has-text-weight-light has-letter-spacing has-text-right"
          htmlFor={"email"}
        >
          TWÓJ EMAIL
        </label>
        <div className="control">
          <input
            className="input is-size-6 has-text-weight-light has-letter-spacing is-styled-input"
            type={"email"}
            name={"email"}
            onChange={handleChange}
            id={"email"}
            required={true}
          />
        </div>
      </div>
      <div className="field mb-5">
        <label
          className="label is-uppercase is-size-7 has-text-weight-light has-letter-spacing has-text-right"
          htmlFor={"message"}
        >
          TREŚĆ WIADOMOŚCI
        </label>
        <div className="control">
          <textarea
            className="textarea is-size-6 has-text-weight-light has-letter-spacing is-styled-input"
            name={"message"}
            onChange={handleChange}
            id={"message"}
            required={true}
          />
        </div>
      </div>
      <div className="field">
        <button
          className="button is-size-7 has-text-weight-light has-letter-spacing is-styled-button"
          type="submit"
        >
          WYŚLIJ
        </button>
      </div>
    </form>
  )
}

export default ContactForm
